import React, { useState, useEffect } from 'react';
import crypto1 from '../../src/images/crypto1.jpg'
function TopHeader() {
    const images = [
        crypto1
        // 'https://ej2.syncfusion.com/products/images/carousel/hunei.png',
        // 'https://ej2.syncfusion.com/products/images/carousel/costa-rica.png',
        // 'https://ej2.syncfusion.com/products/images/carousel/kaohsiung.png',
        // 'https://ej2.syncfusion.com/products/images/carousel/bee-eater.png'
    ];
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [images.length]);

    // const goToSlide = (index) => {
    //     setActiveIndex(index);
    // };

    return (
        <div className="carousel">
            <div className="slides" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
                {images.map((image, index) => (
                    <div key={index} className="slide" style={{ backgroundImage: `url(${image})` }}></div>
                ))}

            </div>
            {/* <div className="dots">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={index === activeIndex ? 'dot active' : 'dot'}
                        onClick={() => goToSlide(index)}
                    ></span>
                ))}
            </div> */}
        </div>

    );
}

export default TopHeader;
